(function() {
	'use strict';
	var $actionbox = '.js-col-actionbox',
		$details = $('.js-details'),
		$thProduct = $('#comparison table thead th.product:not(.feature)'),
		waypoints = avm.require('avast.web.waypoints'),
		hashVal = window.location.hash.substr(1),
		platformsArray = [],
		$window = $(window);

	// set filter by url hash
	$('.filter a').each(function(){platformsArray.push(this.hash.substr(1))});
	if(hashVal.length>0 && platformsArray.indexOf(hashVal) !== -1) {
		filterElements(hashVal)
	}
	// set listener on hash change
	$window.on('hashchange', function(){
		if (platformsArray.indexOf(window.location.hash.substr(1)) !== -1) {
			filterElements(window.location.hash.substr(1))
		}
		if (window.location.hash.substring(1) !== -1) {
			if(window.location.hash.substring(1) === 'mac' ) {
				$('.text-small.js-pc').css('display','none');
				$('.text-small.js-mac').css('display','inline-flex');
			} else if (window.location.hash.substring(1) === 'pc'){
				$('.text-small.js-mac').css('display','none');
				$('.text-small.js-pc').css('display','inline-flex');
			}
		}
	})

	// add margin for footer offset on mobile
	function setStickyMargin(headerElems, margin) {
		if ($window.innerWidth() > 1055 || !margin) {
			headerElems.removeAttr('style')
			return
		}
		headerElems.css('marginBottom', margin)
		headerElems.css('marginTop', -margin)
	}


	// Initialy synthetic all-products click

	// Bind click to filter
	$('.filter').on('click', 'a', filterClick);

	/**
	 *
	 * @param {Object} evt
	 */
	function filterClick(evt) {
		var target = evt.currentTarget,
			suffix = target && target.hash ? target.hash.substr(1) : 'all';

		filterElements(suffix)

		$('.js-avg-comparison-table').removeClass('is-sticky');
	}

	/**
	 * set of filtered elements
	 * @param {string} platform
	 */
	function filterElements(platform) {
		// Highlight filter buttons
		filterContent('a.' + platform, '.filter a');

		// Switch headlines
		filterContent('.headline-' + platform, '#top h1[class^="headline-"]');

		// Switch sections
		filterContent('#section-' + platform, '[id^="section-"]');
	}

	/**
	 * filterContent Switch content visibility
	 * @param {String} selector Selector for element to be switched on and visible on page.
	 * @param {String} all Selector for all elements to be switched off and to be hidden.
	 */
	function filterContent(selector, all) {
		document.querySelectorAll(all).forEach(function(el) {
			el.classList.remove('active');
		});

		document.querySelector(selector).classList.add('active');
	}


	// Mobile version detail toggler
	$details.click(function() {
		$(this).parent().find($actionbox).slideToggle();
		$(this).toggleClass('close');
		event.preventDefault();
	  });

	$window.on('resize scroll', function() {
		var section = $('.filter .filter-items .active').attr('class').replace('active', ''),
			sel = section ? '#section-' + section.trim() + ' .js-avg-comparison-table' : null,
			$visibleTable = $('section.active[role="tabpanel"] #comparison .avg-comparison-table:visible'),
			$tableFooter = null;

		if ($visibleTable.length) {
			$tableFooter = $visibleTable.find('tfoot')
			if (!$tableFooter.length) {
				$tableFooter = $visibleTable.find('tbody tr.border + tr')
			}
		}

		if (sel && waypoints($(sel), null, null, true)) {
			$(sel).addClass('is-sticky');
			if ($thProduct.length && $tableFooter.length) {
				setStickyMargin($thProduct, $tableFooter.outerHeight());
			}
		} else {
			$('.js-avg-comparison-table').removeClass('is-sticky');
			if ($thProduct.length) {
				setStickyMargin($thProduct);
			}
		}
	});

	// Move togglers to the top of the table
	// hotsale don't move toggler
	if (avastGlobals.web.locale !== 'en-us' && avastGlobals.web.locale !== 'en-gb') {
		$(document).ready(function() {
			var selectsList = document.querySelectorAll('[data-actionbox-decoupled-select] .toggler'),
				selects = Array.prototype.slice.call(selectsList);

			selects.forEach(function(el) {
				var key = el.closest('[data-actionbox-decoupled-select]').dataset.actionboxDecoupledSelect,
					target = el.closest('table').querySelector('[data-actionbox-decoupled-select-target="' + key + '"]');

				if (target) {
					target.appendChild(el);
				}
			});
		});
	}

	// GLOWEB-5962 -- Back To School - Sticky message bar
	if (window.IntersectionObserver && (avastGlobals.web.locale == 'en-ww' || avastGlobals.web.locale == 'en-us' || avastGlobals.web.locale == 'en-gb')) {
		var tableStickyWaypoints = document.querySelectorAll(".avg-comparison-table");
		var topStickyWaypoint = document.querySelector("#top");

		if (tableStickyWaypoints && tableStickyWaypoints.length > 0) {
			tableStickyWaypoints.forEach(function (tableStickyWaypoint) {

				var observer = new IntersectionObserver(
					function (entries) {
						entries.forEach(function (entry) {
							if (entry.intersectionRatio <= 0) {
								$('.message-bar.bts').addClass('bts-sticky').fadeIn(1000);
							} else {
								$('.message-bar.bts').removeClass('bts-sticky').fadeIn(1000);
							}
						});
					},
					{ threshold: 0 }
				);
				observer.observe(tableStickyWaypoint);
			});
		} else {
			$('.message-bar.bts').addClass('bts-sticky').fadeIn(1000);
		}

		if (topStickyWaypoint) {
			var observer = new IntersectionObserver(
				function (entries) {
					entries.forEach(function (entry) {
						if (entry.intersectionRatio <= 0) {
							$('.message-bar.bts').removeClass('bts-sticky').fadeIn(1000);
						} else {
							$('.message-bar.bts').addClass('bts-sticky').fadeIn(1000);
						}
					});
				},
				{ threshold: 0 }
			);
			observer.observe(topStickyWaypoint);
		} else {
			$('.message-bar.bts').addClass('bts-sticky').fadeIn(1000);
		}
	}

	// mobile sticky table header buttons scroll fix
	function scrollToSection(element) {
		if ($(".avg-comparison-table.js-avg-comparison-table").hasClass('is-sticky')) {
			var minusPos = 235;
		} else {
			var minusPos = 470;
		}

		var sectionSelect = "";
		if ($("#section-all").hasClass('active')) {
			sectionSelect = "#section-all ";
		} else if ($("#section-pc").hasClass('active')) {
			sectionSelect = "#section-pc ";
		} else if ($("#section-mac").hasClass('active')) {
			sectionSelect = "#section-mac ";
		} else if ($("#section-mobile").hasClass('active')) {
			sectionSelect = "#section-mobile ";
		}

		var position = $(sectionSelect + $(element).attr("href")).offset().top;

		$("body, html").animate({
			scrollTop: position - minusPos
		} /* speed */ );
	}

	$("a[href^='#'].btn-store").on('click', function(e) {
		e.preventDefault();
		scrollToSection(this);
	});

	$("a[href^='#'].btn-store-outline").click(function(e) {
		e.preventDefault();
		scrollToSection(this);
	});

})();
